
        <template>
          <div class="vc-snippet-doc">
            <h2>Draggable 拖拽排序</h2>
<p>提供了方便拖拽排序的功能。</p>
<h3>基础用法</h3>
<p>基础的用法。</p>
<p>水平排序</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-draggable</span> <span class="hljs-attr">:list.sync</span>=<span class="hljs-string">"list"</span> <span class="hljs-attr">prop-key</span>=<span class="hljs-string">"value"</span> <span class="hljs-attr">prop</span>=<span class="hljs-string">"label"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-draggable</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">list</span>: [
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'1'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序1'</span>
          },
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'2'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序2'</span>
          },
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'3'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序3'</span>
          },
        ]
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <p>垂直排序</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-1 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-draggable</span> <span class="hljs-attr">:list.sync</span>=<span class="hljs-string">"list"</span> <span class="hljs-attr">prop-key</span>=<span class="hljs-string">"value"</span> <span class="hljs-attr">prop</span>=<span class="hljs-string">"label"</span> <span class="hljs-attr">direction</span>=<span class="hljs-string">"vertical"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-draggable</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">list</span>: [
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'1'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序1'</span>
          },
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'2'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序2'</span>
          },
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'3'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序3'</span>
          },
        ]
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <!-- 禁止拖拽
:::snippet

```html
<template>
  <ixu-draggable :list.sync="list" prop-key="value" prop="label"></ixu-draggable>
</template>

<script>
  export default {
    data() {
      return {
        list: [
          {
            value: '1',
            label: '排序1'
          },
          {
            value: '2',
            label: '排序2',
            disabled: true
          },
          {
            value: '3',
            label: '排序3',
            disabled: true
          },
        ]
      }
    }
  }
</script>
```
::: -->
<h3>slot 自定义</h3>
<p>自定义用法，可以使用 <code>slot</code> 自定义。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-2 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-draggable</span> <span class="hljs-attr">:list.sync</span>=<span class="hljs-string">"list"</span> <span class="hljs-attr">prop-key</span>=<span class="hljs-string">"value"</span> @<span class="hljs-attr">change</span>=<span class="hljs-string">"handleChange"</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">template</span> #<span class="hljs-attr">item</span>=<span class="hljs-string">"{item}"</span>&gt;</span>
       <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"draggable-custom"</span>&gt;</span>
          label：<span class="hljs-tag">&lt;<span class="hljs-name">span</span> <span class="hljs-attr">v-text</span>=<span class="hljs-string">"item.label"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span> <span class="hljs-tag">&lt;<span class="hljs-name">br</span>&gt;</span>
          value：<span class="hljs-tag">&lt;<span class="hljs-name">span</span> <span class="hljs-attr">v-text</span>=<span class="hljs-string">"item.value"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span> <span class="hljs-tag">&lt;<span class="hljs-name">br</span>&gt;</span>
       <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-draggable</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">list</span>: [
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'1'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序1'</span>
          },
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'2'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序2'</span>
          },
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'3'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序3'</span>
          },
          {
            <span class="hljs-attr">value</span>: <span class="hljs-string">'4'</span>,
            <span class="hljs-attr">label</span>: <span class="hljs-string">'排序4'</span>
          },
        ]
      }
    },
    <span class="hljs-attr">methods</span>: {
      handleChange({currentValue, direction, step}) {
        <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'current choose value: '</span>, currentValue);
        <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'current direction: '</span>, direction);
        <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'step:'</span>, step);
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">style</span> <span class="hljs-attr">lang</span>=<span class="hljs-string">"scss"</span>&gt;</span><span class="css">
  <span class="hljs-selector-class">.draggable-custom</span> <span class="hljs-selector-tag">span</span><span class="hljs-selector-pseudo">:first-of-type</span> {
    <span class="hljs-attribute">color</span>: darkorange;
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>list</td>
<td>列表</td>
<td>Array</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>prop-key</td>
<td>当前行的 key，只写属性</td>
<td>String, number</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>prop</td>
<td>对应列内容的字段名，也可以使用 property 属性</td>
<td>String</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>direction</td>
<td>排列方向</td>
<td>String</td>
<td>horizontal / vertical</td>
<td>horizontal</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>绑定值变化时触发的事件</td>
<td>{ currentValue, direction, step }</td>
</tr>
</tbody>
</table>
<h3>PS:</h3>
<p>参考自：<a href="https://github.com/SortableJS/sortablejs">sortablejs</a></p>

          </div>
        </template>
        <script>
           //
//
//
// //
//
//
// //
//
//
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-draggable',{attrs:{"list":_vm.list,"prop-key":"value","prop":"label"},on:{"update:list":function($event){_vm.list=$event}}})}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      list: [
        {
          value: '1',
          label: '排序1'
        },
        {
          value: '2',
          label: '排序2'
        },
        {
          value: '3',
          label: '排序3'
        },
      ]
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-1":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-draggable',{attrs:{"list":_vm.list,"prop-key":"value","prop":"label","direction":"vertical"},on:{"update:list":function($event){_vm.list=$event}}})}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      list: [
        {
          value: '1',
          label: '排序1'
        },
        {
          value: '2',
          label: '排序2'
        },
        {
          value: '3',
          label: '排序3'
        },
      ]
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-2":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-draggable',{attrs:{"list":_vm.list,"prop-key":"value"},on:{"update:list":function($event){_vm.list=$event},"change":_vm.handleChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"draggable-custom"},[_vm._v("\n        label："),_c('span',{domProps:{"textContent":_vm._s(item.label)}}),_vm._v(" "),_c('br'),_vm._v("\n        value："),_c('span',{domProps:{"textContent":_vm._s(item.value)}}),_vm._v(" "),_c('br')])]}}])})}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      list: [
        {
          value: '1',
          label: '排序1'
        },
        {
          value: '2',
          label: '排序2'
        },
        {
          value: '3',
          label: '排序3'
        },
        {
          value: '4',
          label: '排序4'
        },
      ]
    }
  },
  methods: {
    handleChange({currentValue, direction, step}) {
      console.log('current choose value: ', currentValue);
      console.log('current direction: ', direction);
      console.log('step:', step);
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         











































.draggable-custom span:first-of-type {
  color: darkorange;
}

       </style>